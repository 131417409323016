import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from "vuex";
import Swal from "sweetalert2";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { email, required, minLength, sameAs } from "vuelidate/lib/validators";
import { REGISTER, TERM_AND_CONDITION } from "@/core/services/store/auth.module";
import { API_URL, IMAGE_URL, TITLE_T_AND_C } from "@/config";
export default {
  mixins: [validationMixin],
  name: "RegisterUser",
  data: function data() {
    return {
      TITLE_T_AND_C: TITLE_T_AND_C,
      loading: false,
      showPassword: false,
      previewImage: null,
      file: null,
      term: "",
      form: {
        email: "",
        password: "",
        line: "",
        file: null
      }
    };
  },
  validations: {
    form: {
      full_name: {
        required: required
      },
      phone: {
        required: required
      },
      email: {
        required: required,
        email: email
      },
      accept: {
        required: required
      },
      line: {
        required: required
      },
      password: {
        required: required,
        minLength: minLength(3)
      },
      confirmPassword: {
        required: required,
        sameAsPassword: sameAs("password")
      },
      file: {}
    }
  },
  methods: {
    togglePassword: function togglePassword() {
      this.showPassword = !this.showPassword;
    },
    validateState: function validateState(name) {
      var _this$$v$form$name = this.$v.form[name],
          $dirty = _this$$v$form$name.$dirty,
          $error = _this$$v$form$name.$error;
      return $dirty ? !$error : null;
    },
    getTermAndCondition: function getTermAndCondition() {
      var _this = this;

      this.loading = true;
      this.$store.dispatch(TERM_AND_CONDITION).then(function (data) {
        _this.loading = false;
        console.log("data", data);
        _this.term = data.message;
      }).catch(function () {
        _this.loading = false;
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;

      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      if (!this.$v.form.accept.$model) {
        Swal.fire({
          title: "",
          text: "กรุณายอมรับเงื่อนไขข้อตกลง",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
        return;
      }

      var full_name = this.$v.form.full_name.$model;
      var phone = this.$v.form.phone.$model;
      var email = this.$v.form.email.$model;
      var password = this.$v.form.password.$model;
      var file = this.$v.form.file.$model;
      var line = this.$v.form.line.$model;
      this.loading = true;
      this.$store.dispatch(REGISTER, {
        full_name: full_name,
        phone: phone,
        email: email,
        password: password,
        line: line,
        file: file
      }).then(function (data) {
        _this2.loading = false;

        if (data.isSuccess) {
          // this.$router.push("/wait?email=" + email);
          _this2.$router.push("/login");

          Swal.fire({
            title: "",
            text: data.message,
            icon: "success"
          });
        }
      }).catch(function () {
        _this2.loading = false;
      });
    },
    upload: function upload(file, onUploadProgress) {
      var formData = new FormData();
      formData.append("file", file);
      return axios.post("".concat(API_URL, "/upload/temp2"), formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: onUploadProgress
      });
    },
    pickFile: function pickFile() {
      var _this3 = this;

      var input = this.$refs.fileInput;
      var file = input.files;

      if (file && file[0]) {
        if (file[0]["type"] !== "image/jpeg" && file[0]["type"] !== "image/png") {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }

        this.upload(file[0], function (event) {
          _this3.progress = Math.round(100 * event.loaded / event.total);
        }).then(function (res) {
          var data = res.data;

          if (data) {
            _this3.previewImage = "".concat(IMAGE_URL).concat(data.data);
            _this3.form.file = data.data;
          }
        });
        this.file = file[0];
        this.fileValid = !!this.file;
        this.$emit("input", file[0]);
      }
    },
    removeFile: function removeFile() {
      this.form.file = null;
      this.file = null;
      this.previewImage = null;
    }
  },
  mounted: function mounted() {
    this.getTermAndCondition();
  },
  computed: _objectSpread({}, mapState({
    errors: function errors(state) {
      return state.auth.errorRegister.map(function (x) {
        return x.message;
      });
    }
  }))
};